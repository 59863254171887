import React, { useState } from "react";

export default function ConduiteQualite() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <section id="ConduiteQualite" className="about--section">
      <div className="agence--section--content--box about--section--box">
        <div className="agence--section--content">
          <p className="section--title">✅ École conduite qualité</p>
          <h2 className="section--heading"><span style={{color: 'var(--primary)'}}>Informations</span>{" "}et règles<span style={{color: 'var(--primary)'}}> !</span></h2>
          <p className="agence--section-description">
            <b>🔴 Général</b>
          </p>
          <div className="button-align-section">
            <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="./pdf/EnjeuxDeLaFormation.pdf" target="_blank">
              <button className="btn btn-github">
                <b>Enjeux de la formation</b>
              </button>
            </a>
            <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="./pdf/2.1 Réglement intérieur 2023.pdf" target="_blank">
              <button className="btn btn-github">
                <b>Réglement intérieur</b>
              </button>
            </a>
            <a style={{textDecoration: 'none', color: 'currentColor'}} href="./pdf/PARCOURS-DE-FORMATION-A-LA-CATEGORIE-B-DU-PERMIS-DE-CONDUIRE 2023.pdf" target="_blank">
              <button className="btn btn-github">
                <b>Permis B</b>
              </button>
            </a>
          </div>
          <p className="agence--section-description">
            <b>⚠️ Moto</b>
          </p>
          <div className="button-align-section">
          <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="./pdf/Conditions d'utilisation de la piste moto 2023.pdf" target="_blank">
            <button className="btn btn-github">
              <b>🚧 Conditions d'utilisation : piste</b>
            </button>
          </a>
          <button role="button" onClick={() => setShowModal(true)} className="btn btn-github">
            <b>🧤 Équipements requis</b>
          </button>
          {showModal ? (
          <>
            <div
              style={{justifyContent: 'center', alignItems: 'center', display: 'flex', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
            >
              <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                {/*content*/}
                <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                  {/*header*/}
                  <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                    <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                      🧤 Équipements obligatoires
                    </h3>
                    <button
                      style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                      onClick={() => setShowModal(false)}
                    >
                      <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'center'}}>
                    <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>Pour votre sécurité et par obligation réglementaire, nous vous demandons de vous munir d'un équipement adapaté aux formations deux roues.</h3>
                    <ul style={{listStyle: 'none', paddingInlineStart: 'initial'}}>
                      <li><img src="./img/casque.png" style={{display: 'inline-flex', width: '7rem', verticalAlign: 'middle'}} alt="casque" /><p style={{display: 'inline-block'}}>Casque homologué à la taille du motard</p></li>
                      <li><img src="./img/gants.png" style={{display: 'inline-flex', width: '7rem', verticalAlign: 'middle'}} alt="gants" /><p style={{display: 'inline-block'}}>Gants à la taille et homologués</p></li>
                      <li><img src="./img/chaussures.png" style={{display: 'inline-flex', width: '7rem', verticalAlign: 'middle'}} alt="chaussures" /><p style={{display: 'inline-block'}}>Gants à la taille et homologués</p></li>
                    </ul>
                    <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                      Les élèves se présentant en leçon sans les équipements de sécurité obligatoire se verront refuser l’accès à celle-ci.<br/>
                    </p>
                  </div>
                  {/*footer*/}
                  <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                    <button
                      style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      J'ai compris ✅
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
          </>
          ) : null}
          </div>
          <p className="agence--section-description">
            <b>❓ Autres</b>
          </p>
          <div className="button-align-section">
            <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="./pdf/Valorisation AAC et CS 2023.pdf" target="_blank">
              <button className="btn btn-github">
                <b>AAC et CS</b>
              </button>
            </a>
            <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/permis-probatoire/les-stages-postpermis-et-reduction-de-la-periode" target="_blank">
              <button className="btn btn-github">
                <b>Formation post-permis</b>
              </button>
            </a>
            <a style={{textDecoration: 'none', color: 'currentColor'}} href="./pdf/le procédé d'évaluation 2023.pdf" target="_blank">
              <button className="btn btn-outline-github">
                <b>Procédé d'évaluation</b>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="about--section--img" style={{justifySelf: 'center', maxWidth: '75%'}}>
        <img src="./img/conduitequalite.png"></img>
        <a
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          href="./pdf/questions-verifications-2018-banque-vérifications-01_01_18-2.pdf"
          target="_blank"
          className="navbar--content"
          style={{marginLeft: 'auto', marginRight: 'auto', display: 'table', marginBottom: '2rem'}}
        >
          <button className="btn btn-primary">
            <b>📖 Questions vérifications</b>
          </button>
        </a>
      </div>
    </section>
  );
}
