import React, { useState } from "react";
import { Link } from "react-scroll";

function Footer() {
  const [showModalTerms, setShowModalTerms] = React.useState(false);
  const [showModalConfidentiality, setShowModalConfidentiality] = React.useState(false);
  return (
    <footer className="footer--container noselect">
      <div className="footer--link--container">
        <div>
          <img src="./img/logoDP-2.jpg" style={{maxWidth: '12rem'}} alt="Directt Permis" />
        </div>
        <div className="footer--items">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="landingPage"
                className="text-md"
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Formations"
                className="text-md"
              >
                🎓 Formations
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Vehicules"
                className="text-md"
              >
                🎫 Forfaits
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Agence"
                className="text-md"
              >
                📍 Agence
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Avis"
                className="text-md"
              >
                ⭐ Avis
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="text-md"
              >
                📧 Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100057400280350"
                target="_blank"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                  <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2aa4f4"></stop><stop offset="1" stopColor="#007ad9"></stop></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/search?q=Directt+Permis&sca_esv=580163193&hl=fr-BE&biw=1389&bih=651&tbm=lcl&sxsrf=AM9HkKms_cYdeICfyQTb9_rL5jXmQFwn6Q%3A1699536382813&ei=_t1MZbORMYr8kdUP_uC1oAM&ved=0ahUKEwiziIWzgreCAxUKfqQEHX5wDTQQ4dUDCAk&uact=5&oq=Directt+Permis&gs_lp=Eg1nd3Mtd2l6LWxvY2FsIg5EaXJlY3R0IFBlcm1pczIHECMYigUYJzIHECMYigUYJzIHECMYigUYJzILEC4YrwEYxwEYgAQyBRAAGIAESLkLUJEEWP4JcAB4AJABAJgBWqAB5wOqAQE2uAEDyAEA-AEBwgIGEAAYFhgewgILEC4YgAQYxwEYrwGIBgE&sclient=gws-wiz-local#rlfi=hd:;si:12502842978155215931,l,Cg5EaXJlY3R0IFBlcm1pc0jD8smPqq-AgAhaIBAAEAEYABgBIg5kaXJlY3R0IHBlcm1pcyoGCAIQABABkgEfZHJpdmVyc19saWNlbnNlX3RyYWluaW5nX3NjaG9vbKoBNhABMh4QASIas6xBRi6onf9ZsBXQEDUqdj30KVbp2TIVehMyEhACIg5kaXJlY3R0IHBlcm1pcw;mv:[[50.62753547731903,3.1084436141248855],[50.62717552268097,3.107876185875114]]"
                target="_blank"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                  <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="divider" />
      <div className="footer--content--container">
        <div className="about--section--img" style={{justifySelf: 'center', maxWidth: '15rem'}}>
          <img src="./img/garantie.png"></img>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <p
                role="button" onClick={() => setShowModalConfidentiality(true)}
                className="text-sm"
                style={{cursor: 'pointer'}}
              >
                Politique de confidentialité
              </p>
            </li>
            {showModalConfidentiality ? (
              <>
                <div
                  style={{justifyContent: 'center', alignItems: 'center', display: 'block', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
                >
                  <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                    {/*content*/}
                    <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                      {/*header*/}
                      <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                          👓 Politique de confidentialité
                        </h3>
                        <button
                          style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                          onClick={() => setShowModalConfidentiality(false)}
                        >
                          <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'left'}}>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        En vigueur au 06 Mars 2020 Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par DirecttPermis.com et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                        Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
                        Les CGU doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat entre le site et l'Utilisateur. L’accès au site par l’Utilisateur signifie son acceptation des présentes CGU. Il s’engage désormais à respecter les présentes conditions.
                        Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur.
                        En cas de non-acceptation des CGU l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. DirecttPermis.com se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 1 : Mentions légales</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Édition du site DirecttPermis.com<br/>
                        Raison sociale : SAS DIRECTT PERMIS<br/>
                        Agrément Préfectoral : E1705900210<br/>
                        Siren : 898 712 369 Code NAF: 8553Z<br/>
                        Adresse mail : m.directtpermis@gmail.com<br/>
                        Hébergement : Vercel Inc. 340 S Lemon Ave #4133 Walnut, CA 91789<br/>
                        Développement : francois-leclercq.web.app<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 2 : Accès au site</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Le site DirecttPermis.com permet à l'Utilisateur un accès gratuit.
                        Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet.
                        L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire au sein de l'Auto-Moto École . En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. L’Utilisateur est responsable de la mise à jour des informations fournies.
                        Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son nom d’utilisateur et de son mot de passe qui lui seront communiqués après son inscription et qui sont strictement personnels. A ce titre, il s’en interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait. L’Utilisateur pourra également solliciter sa désinscription au sein de l'Auto-Moto École. Celle-ci sera effective dans un délai raisonnable. En cas de non respect des conditions générales de vente et/ou d’utilisation, le site DirecttPermis.com aura la possibilité de suspendre voire de fermer le compte d’un Utilisateur après mise en demeure adressée par voie électronique et restée sans effet.
                        Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes informations personnelles de l’Utilisateur.
                        Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de DirecttPermis.com. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 3 : Données personnelles</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit : • par mail m.directtpermis@gmail.com • par voie postale au 24 Chemin DES CRIEURS 59650 VILLENEUVE D'ASCQ • au seins de l'Auto-Moto École.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 4 : Propriété intellectuelle</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
                        L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
                        Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
                        Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 5 : Responsabilité</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Les sources des informations diffusées sur le site DirecttPermis.com sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
                        Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site DirecttPermis.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
                        L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.
                        Le site DirecttPermis.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.
                        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 6 : Liens hypertextes</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site DirecttPermis.com. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 7 : Cookies</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
                        Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site DirecttPermis.com. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br/>
                        L’information contenue dans les cookies est utilisée pour améliorer le site, par exemple en :<br/>
                        • permettant à un service de reconnaître l’appareil de l’Utilisateur, pour qu’il n’ait pas à donner les mêmes informations à plusieurs reprises, par exemple remplir un formulaire ou une enquête.<br/>
                        • mémorisant que vous l’Utilisateur a déjà donné ses identifiant et mot de passe, pour ne pas avoir à le refaire à chaque nouvelle page.<br/>
                        • surveillant comment les utilisateurs se servent des services, pour les rendre plus simples d’utilisation et allouer suffisamment de puissance pour s’assurer de leur réactivité.<br/>
                        • analysant des données « anonymisées » pour aider à comprendre comment les utilisateurs interagissent avec les différents aspects des services en ligne et donc permettre de les améliorer.<br/>
                        En naviguant sur le site, L’Utilisateur les accepte. A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 8 : Droit applicable et juridiction compétente</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
                        </p>
                      </div>
                      {/*footer*/}
                      <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <button
                          style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                          type="button"
                          onClick={() => setShowModalConfidentiality(false)}
                        >
                          J'ai compris ✅
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
              </>
              ) : null}
            <li>
              <p
                role="button" onClick={() => setShowModalTerms(true)}
                className="text-sm"
                style={{cursor: 'pointer'}}
              >
                Termes d'utilisation
              </p>
            </li>
            {showModalTerms ? (
              <>
                <div
                  style={{justifyContent: 'center', alignItems: 'center', display: 'block', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
                >
                  <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                    {/*content*/}
                    <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                      {/*header*/}
                      <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                          📑 Termes d'utilisation
                        </h3>
                        <button
                          style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                          onClick={() => setShowModalTerms(false)}
                        >
                          <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'left'}}>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        En vigueur au 06 Mars 2020 Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par DirecttPermis.com et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                        Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
                        Les CGU doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat entre le site et l'Utilisateur. L’accès au site par l’Utilisateur signifie son acceptation des présentes CGU. Il s’engage désormais à respecter les présentes conditions.
                        Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur.
                        En cas de non-acceptation des CGU l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. DirecttPermis.com se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 1 : Mentions légales</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Édition du site DirecttPermis.com<br/>
                        Raison sociale : SAS DIRECTT PERMIS<br/>
                        Agrément Préfectoral : E1705900210<br/>
                        Siren : 898 712 369 Code NAF: 8553Z<br/>
                        Adresse mail : m.directtpermis@gmail.com<br/>
                        Hébergement : Vercel Inc. 340 S Lemon Ave #4133 Walnut, CA 91789<br/>
                        Développement : francois-leclercq.web.app<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 2 : Accès au site</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Le site DirecttPermis.com permet à l'Utilisateur un accès gratuit.
                        Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet.
                        L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire au sein de l'Auto-Moto École . En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. L’Utilisateur est responsable de la mise à jour des informations fournies.
                        Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son nom d’utilisateur et de son mot de passe qui lui seront communiqués après son inscription et qui sont strictement personnels. A ce titre, il s’en interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait. L’Utilisateur pourra également solliciter sa désinscription au sein de l'Auto-Moto École. Celle-ci sera effective dans un délai raisonnable. En cas de non respect des conditions générales de vente et/ou d’utilisation, le site DirecttPermis.com aura la possibilité de suspendre voire de fermer le compte d’un Utilisateur après mise en demeure adressée par voie électronique et restée sans effet.
                        Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes informations personnelles de l’Utilisateur.
                        Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de DirecttPermis.com. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.<br/><br/>
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 3 : Données personnelles</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit : • par mail m.directtpermis@gmail.com • par voie postale au 24 Chemin DES CRIEURS 59650 VILLENEUVE D'ASCQ • au seins de l'Auto-Moto École.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 4 : Propriété intellectuelle</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
                        L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
                        Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
                        Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 5 : Responsabilité</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Les sources des informations diffusées sur le site DirecttPermis.com sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
                        Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site DirecttPermis.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
                        L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.
                        Le site DirecttPermis.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.
                        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 6 : Liens hypertextes</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site DirecttPermis.com. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 7 : Cookies</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
                        Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site DirecttPermis.com. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br/>
                        L’information contenue dans les cookies est utilisée pour améliorer le site, par exemple en :<br/>
                        • permettant à un service de reconnaître l’appareil de l’Utilisateur, pour qu’il n’ait pas à donner les mêmes informations à plusieurs reprises, par exemple remplir un formulaire ou une enquête.<br/>
                        • mémorisant que vous l’Utilisateur a déjà donné ses identifiant et mot de passe, pour ne pas avoir à le refaire à chaque nouvelle page.<br/>
                        • surveillant comment les utilisateurs se servent des services, pour les rendre plus simples d’utilisation et allouer suffisamment de puissance pour s’assurer de leur réactivité.<br/>
                        • analysant des données « anonymisées » pour aider à comprendre comment les utilisateurs interagissent avec les différents aspects des services en ligne et donc permettre de les améliorer.<br/>
                        En naviguant sur le site, L’Utilisateur les accepte. A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.<br /><br />
                        </p>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>ARTICLE 8 : Droit applicable et juridiction compétente</h3>
                        <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                        La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
                        </p>
                      </div>
                      {/*footer*/}
                      <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <button
                          style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                          type="button"
                          onClick={() => setShowModalTerms(false)}
                        >
                          J'ai compris ✅
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
              </>
              ) : null}
          </ul>
        </div>
      </div>
      <p className="footer--content">&copy; {new Date().getFullYear()} 🚦 Directt Permis - Auto-Moto École. Tous droits réservés.</p>
    </footer>
  );
}

export default Footer;
