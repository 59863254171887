import data from "../../data/index.json";

export default function Vehicules() {
  return (
    <section className="permis--section" id="Vehicules">
      <div className="formations--container">
        <p className="section--title">🎫 Nos forfaits</p>
        <h2 className="section--heading">Nous vous proposons des <span style={{color: 'var(--primary)'}} >véhicules adaptés</span>{" "}selon le <span style={{color: 'var(--primary)'}} >forfait</span> que vous avez besoin !</h2>
      </div>
      <div className="permis--section--container">
        {data?.vehicules?.map((item, index) => (
          <div key={index} className="permis--section--card">
            <div className="permis--section--img">
              <img src={item.src} style={{width: '10rem'}} alt="Image Vehicule" />
            </div>
            <div className="permis--section--card--content">
              <h3 className="permis--section--title">{item.title}</h3>
              <p style={{marginTop: '-1rem'}}><i>{item.modele}</i></p>
              <p className="permis--section--description">{item.description}</p>
              <b style={{textDecoration: 'none', color: 'currentColor'}}>Renseignez vous en agence</b>
              {/*
              <a style={{textDecoration: 'none', color: 'currentColor'}} href={item.href} target="_blank">
                <button className="btn btn-github">
                  <b>+ Informations</b>
                </button>
              </a>
              */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
