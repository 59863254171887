import data from "../../data/index.json";

export default function Mediateur() {
  return (
    <section className="testimonial--section" id="Mediateur">
      <div className="formations--container-box">
        <div className="formations--container">
          <h2 className="sections--heading"><b>🚨 Médiateur</b></h2>
          <p className="sub--title">
            Vous rencontrez des difficultés ? N'hésitez pas à en faire part à notre référent, Mr Wasson Fabien.<br />
            Vous pouvez par ailleurs contacter le Médiateur Mobilians 43 rue de Vaugirard CS 80016, 92197 MEUDON CEDEX.<br />
            <span style={{color: 'var(--primary)'}} >mediateur@mediateur-mobilians.fr</span>
          </p>
        </div>
        <a
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          href="https://www.mediateur-mobilians.fr/"
          target="_blank"
          className="navbar--content"
        >
          <button className="btn btn-primary">
            <b>www.mediateur-mobilians.fr</b>
          </button>
        </a>
      </div>
    </section>
  );
}
