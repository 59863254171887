import React from "react";
import data from "../data/index.json";

export default function Service() {
  return (
    <div className="formations--section--container">
        {data?.permis?.map((props, index) => (
        <div key={index} className="formations--section--card">
            <div className="formations--section--img">
                <img src={props.src} alt="Placeholder" />
            </div>
            <div className="formations--section--card--content">
                <div>
                <h3 className="formations--section--title">{props.title}</h3>
                <p className="text-md">{props.description}</p>
                </div>
                <a href={props.href} target="_blank" className="text-sm formations--link">
                + Voir plus
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 20 19"
                    fill="none"
                >
                    <path
                    d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                    stroke="currentColor"
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </svg>
                </a>
            </div>
        </div>
        ))}
    </div>  
  );
}
