import React from "react";

export default function Product(props) {
  return (
    <div className="card" style={{borderRadius: '0.5rem'}}>
      <img className="product--image" src={props.src} alt="product image" style={{borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', backgroundColor: 'var(--bg-shade)'}}/>
      <h2>{props.name}</h2>
      <p className="price">{props.price}</p>
      <p style={{margin: '1rem'}}>{props.description} - "{props.name}"<br /> ci-dessous :</p>
      <a href={props.href} target="_blank">
        <button style={{borderRadius: '10rem', marginBottom: '1rem', padding: '0.5rem', width: '80%'}} ><b>En savoir +</b></button>
      </a>
    </div>
  );
}
