import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '95vh', fontSize: '2rem'}}>
                <h2>
                  404 Not Found
                </h2>
                <a
                  activeClass="navbar--active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  href="/"
                  className="navbar--content"
                >
                  <button className="">
                    <b>Retour</b>
                  </button>
                </a>
              </div>
            }>
            </Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
