import Landing from "../Landing";
import Formations from "../Formations";
import Vehicules from "../Vehicules";
import Agence from "../Agence";
import Avis from "../Avis";
import ConduiteQualite from "../ConduiteQualite";
import Inscription from "../Inscription";
import Mediateur from "../Mediateur";
import Contact from "../Contact";
import Footer from "../Footer";

export default function Home() {
  return (
    <>
      <Landing />
      <Formations />
      <Vehicules />
      <Agence />
      <Avis />
      <ConduiteQualite />
      <Inscription />
      <Mediateur />
      <Contact />
      <Footer />
    </>
  );
}
