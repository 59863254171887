import React, { useState } from "react";
import data from "../../data/index.json";
import Service from "../../data/Service";

export default function Incription() {
  const [showModalPermisB, setShowModalPermisB] = React.useState(false);
  const [showModalPermisA, setShowModalPermisA] = React.useState(false);
  const services = data?.permis?.map((item, index) => (
    <Service
      src={item.src}
      title={item.title}
      description={item.description}
      href={item.href}
    />
  ));
  return (
    <section className="permis--section" id="Incription" style={{gridTemplateColumns: 'repeat(2, 1fr) !important', display: 'inline-flex !important', marginTop: '-4rem'}}>
      <p className="section--title" style={{marginTop: '2rem', marginBottom: '-5rem'}}>📜 Inscription</p>
      <div className="inscription--section--container">
          <div className="permis--section--card">
            <div className="permis--section--img">
              <img src="./img/permisB.png" style={{width: '10rem'}} alt="Image Vehicule" />
            </div>
            <div className="permis--section--card--content">
              <h3 className="permis--section--title">Inscription Permis B</h3>
              <p className="permis--section--description">Retrouvez ci-joint les différents éléments à fournir lors de votre inscription au permis B. Pour permis B (Manuelle & auto) / BE / B96 / AM (quadri léger).</p>
              <a style={{textDecoration: 'none', color: 'currentColor'}}>
                <button role="button" onClick={() => setShowModalPermisB(true)} className="btn btn-github">
                  <b>📁 Documents à fournir</b>
                </button>
              </a>
              {showModalPermisB ? (
              <>
                <div
                  style={{justifyContent: 'center', alignItems: 'center', display: 'block', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
                >
                  <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                    {/*content*/}
                    <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                      {/*header*/}
                      <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                          🚗 Inscription Permis B
                        </h3>
                        <button
                          style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                          onClick={() => setShowModalPermisB(false)}
                        >
                          <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'left'}}>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>📁 Liste des documents à fournir :</h3>
                        <ul style={{listStyle: 'none', paddingInlineStart: 'initial'}}>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 1 photocopie recto verso couleur de la pièce d'identité ou titre de séjour en cours de validité.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• Votre photocopie recto/verso couleur de votre permis actuel autre que la catégorie demandée.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 4 photos d’identité Ephotos agréé service en ligne ANTS (avec le numéro Ephotos unique).</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• Si vous êtes français et âgé de 17 ans révolus à 25 ans non révolus, une photocopie de l'attestation provisoire en instance de c onvocation à la JDC et/ou le certificat de participation à l'appel de préparation à la défense.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 1 Justificatif de domicile de moins de 6 mois ou si vous êtes hébergé : le justificatif, la pièce d’identité recto/ verso couleur et une attestation de logement de la personne qui vous héberge (facture de gaz, edf, téléphone).</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 2 timbres et 1 enveloppe.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• Votre numéro de téléphone portable + adresse email.</p></li>
                          <li><a style={{display: 'inline-block', margin: '0.5rem', color: 'var(--primary'}} href="./pdf/Nouveau Forfait B juillet 2024.pdf" target="_blank">🔗 Fiche Permis B</a></li>

                        </ul>
                      </div>
                      {/*footer*/}
                      <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <button
                          style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                          type="button"
                          onClick={() => setShowModalPermisB(false)}
                        >
                          J'ai compris ✅
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
              </>
              ) : null}
            </div>
          </div>
          <div className="permis--section--card">
            <div className="permis--section--img">
              <img src="./img/permisA.png" style={{width: '10rem'}} alt="Image Vehicule" />
            </div>
            <div className="permis--section--card--content">
              <h3 className="permis--section--title">Inscription Permis A</h3>
              <p className="permis--section--description">Retrouvez ci-joint les différents éléments à fournir lors de votre inscription au permis A. Pour permis A / A1 / A2 / L5E / 125cm3 / BSR (50cm3) / AM.</p>
              <a style={{textDecoration: 'none', color: 'currentColor'}}>
                <button role="button" onClick={() => setShowModalPermisA(true)} className="btn btn-github">
                  <b>📁 Documents à fournir</b>
                </button>
              </a>
              {showModalPermisA ? (
              <>
                <div
                  style={{justifyContent: 'center', alignItems: 'center', display: 'block', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
                >
                  <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                    {/*content*/}
                    <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                      {/*header*/}
                      <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                          🏍️ Inscription Permis A
                        </h3>
                        <button
                          style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                          onClick={() => setShowModalPermisA(false)}
                        >
                          <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'left'}}>
                        <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>📁 Liste des documents à fournir :</h3>
                        <ul style={{listStyle: 'none', paddingInlineStart: 'initial'}}>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 1 photocopie recto verso couleur de la pièce d'identité ou titre de séjour en cours de validité.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 1 photocopie couleur recto verso du permis actuel.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 4 photos d’identité Ephotos agréé service en ligne ANTS (avec le numéro Ephotos unique).</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• Si vous êtes français et âgé de 17 ans révolus à 25 ans non révolus, une photocopie de l'attestation provisoire en instance de convocation à la JDC et/ou le certificat de participation à l'appel de préparation à la défense.</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• ASSR 1 ou assr 2 ou asr (pour AM ou pour première demande de permis).</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 1 Justificatif de domicile de moins de 6 mois ou si vous êtes hébergé : le justificatif, la pièce d’identité recto/ verso couleur et une attestation de logement de la personne qui vous héberge (facture de gaz, edf, telephone).</p></li>
                          <li><p style={{display: 'inline-block', margin: '0.5rem'}}>• 2 timbres et 1 enveloppe.</p></li>
                          <li><a style={{display: 'inline-block', margin: '0.5rem', color: 'var(--primary'}} href="./pdf/Nouveau Forfait 2 roues juillet 2024.pdf" target="_blank">🔗 Fiche Permis A</a></li>

                        </ul>
                      </div>
                      {/*footer*/}
                      <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                        <button
                          style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                          type="button"
                          onClick={() => setShowModalPermisA(false)}
                        >
                          J'ai compris ✅
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
              </>
              ) : null}
            </div>
          </div>
      </div>
      <p className="section--title" style={{marginTop: '2rem', marginBottom: '-5rem'}}>🎯 En savoir plus sur nos prestations de services</p>
      <Service>
        {services}
      </Service>
    </section>
  );
}
